<template>
  <div>
    <v-textarea
      :value="value"
      :error-messages="errors"
      :label="label"
      :disabled="disabled"
      @input="$emit('input', $event)"
      :required="required"
      class="input-field input-field_textarea"
    >
      > 00712100
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: "TextareaField",
  props: {
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    value: {
      type: String
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  }
};
</script>
