<template>
  <MainLayout>
    <template v-slot:navbar-title> Редактировать </template>

    <CreateFormLayout>
      <template v-slot:title__text>
        Заявка
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Данные заявки</FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              v-model="title"
              label="Тема"
              validate-name="тема"
            ></ValidationInputField>
            <ValidationSelectField
              v-model="task_type"
              :items="[
                { text: 'Добавление контроллера', value: 'add' },
                { text: 'Ремонт', value: 'repair' }
              ]"
              rules="required"
              label="Тип заявки"
              validate-name="тип заявки"
            ></ValidationSelectField>

            <ValidationSelectField
              v-model="priority"
              :items="[
                { text: 'Высокий', value: 1 },
                { text: 'Обычный', value: 2 }
              ]"
              rules="required"
              label="Приоритет"
              validate-name="приоритет"
            ></ValidationSelectField>

            <ValidationAutocompleteField
              v-model="object"
              :search-function="objectSearch"
              rules="required"
              label="Объект"
              :return-object="false"
              validate-name="объект"
            />

            <ValidationAutocompleteField
              v-model="controller"
              :search-function="controllerSearch"
              label="Контроллер"
              :return-object="false"
              validate-name="контроллер"
            />
            <ValidationDateField
              v-model="deadline_at"
              rules="required"
              label="Срок выполнения"
              validate-name="срок выполнения"
            ></ValidationDateField>
            <ValidationTextareaField
              v-model="description"
              label="Текст комментария"
              validate-name="текст комментария"
            ></ValidationTextareaField>
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>

        <v-btn
          type="submit"
          @click="handleSubmit(editServiceTask)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";

import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField";
import ValidationDateField from "@/components/ValidationField/ValidationDateField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import { getObjectsRequest } from "@/helpers/api/objects";
import { getControllersRequest } from "@/helpers/api/controllers";
// import { deleteServiceTaskByIdRequest } from "@/helpers/api/serviceTasks";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditServiceTaskPage",
  components: {
    FormInputBlock,
    CreateFormLayout,
    MainLayout,
    ValidationSelectField,
    ValidationInputField,
    ValidationAutocompleteField,
    ValidationDateField,
    ValidationTextareaField,
    FormCardTitle
  },

  data() {
    return {
      loading: false,
      objectSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        const isService = [50].includes(this.$store.getters.getCurrentRole);
        const pageOrgId = this.$route.params.serviceOrganizationId;
        return (
          await getObjectsRequest({
            query: {
              search: value,
              limit: 5000,
              service_organization: isService ? id : pageOrgId
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      controllerSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        const isService = [50].includes(this.$store.getters.getCurrentRole);
        const pageOrgId = this.$route.params.serviceOrganizationId;
        return (
          await getControllersRequest({
            query: {
              search: value,
              object: this.object,
              limit: 5000,
              object__service_organization: isService ? id : pageOrgId
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    priority: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.priority.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "priority",
          value: newValue
        });
      }
    },
    title: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.title.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "title",
          value: newValue
        });
      }
    },
    description: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.description.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "description",
          value: newValue
        });
      }
    },
    controller: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.controller.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "controller",
          value: newValue
        });
      }
    },
    object: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.object.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    task_type: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.task_type.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "task_type",
          value: newValue
        });
      }
    },
    deadline_at: {
      get() {
        return this.$store.getters.getServiceTaskEditForm.deadline_at.value;
      },
      set(newValue) {
        this.$store.commit("changeEditServiceTask", {
          fieldName: "deadline_at",
          value: newValue
        });
      }
    }
  },
  created() {
    this.$store.dispatch("prepareEditServiceTask", {
      id: this.$route.params.id
    });
  },
  beforeDestroy() {
    this.$store.commit("clearEditServiceTaskForm");
  },
  methods: {
    editServiceTask() {
      this.$store
        .dispatch("editServiceTask", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.serviceTask)
          );
        });
    }
  }
};
</script>

<style scoped></style>
