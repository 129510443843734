<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <TextareaField
      :value="value"
      :disabled="disabled"
      :label="label"
      :errors="errors"
      :required="required"
      @input="$emit('input', $event)"
    ></TextareaField>
  </ValidationProvider>
</template>

<script>
import TextareaField from "@/components/fields/TextareaField";
export default {
  name: "ValidationTextareaField",
  components: { TextareaField },
  props: {
    rules: {
      type: String
    },
    validateName: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
